import axios from 'axios';
import authHeader from '../utils/authHeaderUtils';
import { handleAxiosError } from '../utils/commonUtils';
import { SuccessResponse } from '../models/system/successResponse';


const API_URL = process.env.REACT_APP_BASE_URL;

class UsersApi {

    private integration = 'looker' as string;
    private platform = 'looker' as string;
    
    async getUserTeam() {
        try {
            const response = await axios.get(`${API_URL}/users/me/teams/me`, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

}

export default new UsersApi();
