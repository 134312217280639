import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { GA_ID } from './config/systemConfig';
import Connections from './pages/Connections';
import Login from './pages/Login';
import SelectTeams from './pages/SelectTeams';


function Analytics() {
  const location = useLocation();

  useEffect(() => {
    if (GA_ID) {
      ReactGA.initialize(GA_ID);  // Initialize with your GA ID
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);

  return null;
}


function App() {
  return (
    <div className="App">
      <Router>
        <Analytics />
        <Switch>
          {/* Login Route as the Default Page */}
          <Route path="/login" component={Login} exact={true} />

          {/* Protected Routes */}
          <Route path="/connections" component={Connections} exact={true} />
          <Route path="/select_teams" component={SelectTeams} exact={true} />

          {/* Redirect root path to /login */}
          <Redirect from="/" to="/login" exact={true} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
