import axios from 'axios';
import authHeader from '../utils/authHeaderUtils';
import { API_URL } from '../config/systemConfig';
import { handleAxiosError } from '../utils/commonUtils';
import { SuccessResponse } from '../models/system/successResponse';

class ConnectionsApi {

    private integration = 'looker' as string;
    private platform = 'connector' as string;
    
    async getConnections(data_source_key: string) {
        try {
            const response = await axios.get(`${API_URL}/${data_source_key}/connections?platform=${this.platform}&integration=${this.integration}`, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

}

export default new ConnectionsApi();
