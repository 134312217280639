import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';
import AuthApi from '../api/authApi';
import Logo from "../assets/images/everythingdata-logo.png";
import GoogleLogo from "../assets/images/google_g_logo.png";
import '../css/styles.css';
import { SuccessResponse } from '../models/system/successResponse';
import { handleAxiosError } from '../utils/commonUtils';
import { getCookie, setCookie, removeCookie } from '../utils/cookiesUtils';


export default function Login() {
    const [loginUrl, setLoginUrl] = useState('');
    const location = useLocation();
    const history = useHistory();
    const integration: string = 'looker';


    const setGoogleLoginUrl = async (data_source: string, looker_redirect_uri: string, looker_state: string) => {
        try {
            const redirect_url = encodeURIComponent(
                `${window.location.origin}/login?data_source=${data_source}&integration=${integration}&looker_redirect_uri=${looker_redirect_uri}&looker_state=${looker_state}`
            );

            const response = await AuthApi.getGoogleLoginUrl(redirect_url);
            if (response.success) {
                const data = (response as SuccessResponse).data;
                setLoginUrl(data.login_url);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error(handleAxiosError(error).message);
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const data_source = params.get('data_source') || '';
        const looker_redirect_uri = params.get('looker_redirect_uri') || params.get('redirect_uri') || '';
        const looker_state = params.get('looker_state') || params.get('state') || '';

        removeCookie('auth_code');

        const existing_dashboard_token = getCookie('dashboard_token');
        const token = params.get('token');

        if (token || existing_dashboard_token) {
            if (token) {
                setCookie('dashboard_token', token, 20);
            }

            const queryParams = new URLSearchParams({
                data_source,
                integration,
                looker_redirect_uri,
                looker_state,
            }).toString();

            history.push(`/connections?${queryParams}`);
        } else {
            setGoogleLoginUrl(data_source, looker_redirect_uri, looker_state);
        }
    }, [location.search, history]);

    return (
        <div className="login-wrapper">
            <div className="login-container">
                {/* Logo */}
                <img src={Logo} alt="EverythingData Logo" className="login-logo" />

                {/* Login Button */}
                <button
                    className={`login-button ${loginUrl ? '' : 'disabled-button'}`}
                    onClick={() => { if (loginUrl) window.location.href = loginUrl; }}
                    disabled={!loginUrl}
                >
                    <img src={GoogleLogo} alt="Google Logo" className="google-logo" />
                    <span>Continue with Google</span>
                </button>

                {/* Login Message */}
                <p className="login-text">
                    Please sign in to continue to everythingdata.
                </p>
            </div>
        </div>
    );
}
