import axios from 'axios';
import authHeader from '../utils/authHeaderUtils';
import { API_URL } from '../config/systemConfig';
import { handleAxiosError } from '../utils/commonUtils';
import { SuccessResponse } from '../models/system/successResponse';

class AuthApi {

    private integration = 'looker' as string;
    private platform = 'connector' as string;

    async createAuthCode() {
        try {
            
            console.log('IN AUTH API');
            console.log(JSON.stringify(authHeader()))
            


            const response = await axios.post(`${API_URL}/auth/auth-code`, {}, {
               headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async getGoogleLoginUrl(url: string) {
        try {
            const response = await axios.get(API_URL + '/auth/google/login_url?redirect_url=' + url);
            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async getUserTeams(userId: string) {
        try {
            const response = await axios.get(`${API_URL}/auth/${userId}/teams/`, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }
    
    async selectTeam(team_id: any, user_id: any, callbackUrl: any) {
        const data =
        {
            teamId: team_id,
            userId: user_id,
            // callbackUrl: callbackUrl,
        };

        try {
            const response = await axios.post(`${API_URL}/auth/me/teams/select?platform=${this.platform}&integration=${this.integration}`, data, {
               // headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }
}

export default new AuthApi();
