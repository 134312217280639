import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import AuthApi from '../api/authApi';
import DataSourcesApi from '../api/dataSourcesApi';
import Header from '../components/Header';
import '../css/styles.css';
import { IDataSource } from '../models/platform/dataSources';
import { SuccessResponse } from '../models/system/successResponse';
import { handleAxiosError } from '../utils/commonUtils';
import { setCookie } from '../utils/cookiesUtils';


interface Team {
  _id: string;
  name: string;
}

interface LocationState {
  data_data_source?: IDataSource;
}


const SelectTeams: React.FC = () => {
  const location = useLocation();
  const locationState = location.state as LocationState | undefined;

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const history = useHistory();

  const redirect_uri = queryParams.redirect_uri as string;

  const user_id = queryParams.user_id as string;
  const data_source = queryParams.data_source as string;
  const integration = queryParams.integration as string;
  const looker_redirect_uri = queryParams.looker_redirect_uri as string;
  const looker_state = queryParams.looker_state as string;

  const [userTeams, setUserTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState('');

  const data_data_source = locationState?.data_data_source;
  const [dataSource, setDataSource] = useState<IDataSource | undefined>(data_data_source);


  const handleTeamSelect = async (teamId: string) => {
    try {
      const data = await selectTeam(teamId, user_id, redirect_uri);
      
      setCookie('dashboard_token', data.token, 20);

      history.push({
        pathname: '/connections',
        search: `?user_id=${encodeURIComponent(user_id)}&data_source=${encodeURIComponent(data_source)}&integration=${encodeURIComponent(integration)}&looker_redirect_uri=${encodeURIComponent(looker_redirect_uri)}&looker_state=${encodeURIComponent(looker_state)}`,
        state: { user_id, data_data_source: dataSource },
      });
    } catch (error) {
      toast.error(handleAxiosError(error).message);
    }
  };


  const getDataSources = async (data_source: string) => {
    try {
      const response = await DataSourcesApi.getDataSources(data_source);
      if (response.success) {
        const data = (response as SuccessResponse).data as IDataSource;

        setDataSource(data);
      }
      else {
        toast.error(response.message);
      }
    }
    catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };

  const getUserTeams = async () => {
    try {
      const response = await AuthApi.getUserTeams(user_id);
      if (response.success) {
        const data = (response as SuccessResponse).data;
      setUserTeams(data);
        setSelectedTeam(data[0]?._id);
      } else {
        toast.error(response.message);
      }
    } catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };

  const selectTeam = async (team_id: string, user_id: string, callbackUrl: string) => {
    try {
      const response = await AuthApi.selectTeam(team_id, user_id, callbackUrl);
      if (response.success) {
        return (response as SuccessResponse).data;
      } else {
        toast.error(response.message);
      }
    } catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };


  useEffect(() => {
    getUserTeams();
    getDataSources(data_source);
  }, []);


  return (
    <div>
      <Header iconUrl={dataSource?.logo_url || ''} />
      <div className="content-container">
        <h1>Choose your everythingData team</h1>
        <h2 className="team-description">
          You belong to multiple teams. Please choose a team to associate with this connection:
        </h2>
        {userTeams.length === 0 ? (
          <p>No teams available.</p>
        ) : (
          <ul className="team-list">
            {userTeams.map((team) => (
              <li
                key={team._id}
                onClick={() => handleTeamSelect(team._id)}
                className="team-list-item"
              >
                <div className="team-details">
                  <span className="team-name">{team.name}</span>
                  <span className="team-id">ID: {team._id}</span>
                </div>
                <span className="select-label">SELECT</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SelectTeams;
