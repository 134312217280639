import axios from 'axios';
import { API_URL } from '../config/systemConfig';
import { SuccessResponse } from '../models/system/successResponse';
import authHeader from '../utils/authHeaderUtils';
import { handleAxiosError } from '../utils/commonUtils';


class DataSourcesApi {

    private integration = 'looker' as string;
    private platform = 'connector' as string;

    async getDataSources(data_source_key: string) {
        try {
            const response = await axios.get(`${API_URL}/data-sources/${data_source_key}`, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }
    
}

export default new DataSourcesApi();
