import React from 'react';
import myLogo from '../assets/images/everythingdata-name.png';

interface HeaderProps {
  iconUrl: string;
}

const Header: React.FC<HeaderProps> = ({ iconUrl }) => {
  return (
    <header className="header">
      {/* Display the first logo on the left */}
      <img src={myLogo} alt="My Logo" className="logo" />

      {/* Use a div with CSS to align the second logo to the right */}
      <div className="right-aligned-logo">
        <img src={iconUrl} alt="Additional Logo" className="data-source-logo" />
      </div>
    </header>
  );
};

export default Header;
